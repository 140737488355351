// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import UserRegisterRequestDTO from '../../dto/resource/UserRegisterRequestDTO';

export default abstract class UserRegisterRequestModel extends BaseModel<UserRegisterRequestDTO> {
  /**
  * @type {email}
  */
  get email() { return this.dto.email; }
  set email(value) { this.dto.email = value; }
  /**
  */
  get userName() { return this.dto.userName; }
  set userName(value) { this.dto.userName = value; }
  /**
  */
  get password() { return this.dto.password; }
  set password(value) { this.dto.password = value; }
  /**
  */
  get userLanguage() { return this.dto.userLanguage; }
  set userLanguage(value) { this.dto.userLanguage = value; }
  /**
  */
  get userCulture() { return this.dto.userCulture; }
  set userCulture(value) { this.dto.userCulture = value; }
  /**
  */
  get isGdprConsentAccepted() { return this.dto.isGdprConsentAccepted; }
  set isGdprConsentAccepted(value) { this.dto.isGdprConsentAccepted = value; }
  /**
  */
  get registrationKey() { return this.dto.registrationKey; }
  set registrationKey(value) { this.dto.registrationKey = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.email = queries.email;
    this.dto.userName = queries.userName;
    this.dto.password = queries.password;
    this.dto.userLanguage = queries.userLanguage;
    this.dto.userCulture = queries.userCulture;
    value = queries.isGdprConsentAccepted === 'true' ? true : queries.isGdprConsentAccepted;
    value = queries.isGdprConsentAccepted === 'false' ? false : undefined;
    this.dto.isGdprConsentAccepted = value;
    this.dto.registrationKey = queries.registrationKey;
  }
}
