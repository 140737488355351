import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';
import UserRegisterRequestModel from '../../generated/types/model/resource/UserRegisterRequestModel';

export default class UserRegisterRequestViewModel extends UserRegisterRequestModel {
  protected transformToDTO() {}
  protected transformFromDTO() {}

  schema = {
    ...queryBuilder.properties({
      userName: { required: true, pattern: validationPatterns.email },
      password: { required: true, minLength: 6 },
      passwordRepeat: { required: true, minLength: 6, sameAs: 'password' },
      userLanguage: { required: false },
      userCulture: { required: false },
    }),
  } as IJSONSchema;
}
