
































































import Vue from 'vue';
import UserRegisterRequestViewModel from '@/src/services/viewModel/resource/UserRegisterRequestViewModel';

export default Vue.extend({
  auth: false,
  layout: 'layout-exterior',
  data: () => ({
    user: new UserRegisterRequestViewModel(),
    isLoading: false,
    requestError: undefined,
  }),
  mounted() {
    this.loadQRData();
  },
  methods: {
    tabToNextInput() {
      const inputs = document.getElementsByTagName('input');
      for (let i = 0; i < inputs.length; i += 1) {
        const input = inputs[i];
        if (document!.activeElement!.id === input.id && i + 1 < inputs.length) {
          const nextInput = inputs[i + 1] as HTMLInputElement;
          nextInput.focus();
          break;
        }
      }
    },
    loadQRData() {
      const query = this.$router.currentRoute?.query ?? {};
      const { username, email, registrationKey } = query as Record<string, string>;
      this.user.userName = email;
      this.user.email = email;
      this.user.registrationKey = registrationKey;
    },
    gotoVerifyUser() {
      this.$router.push('/_exterior/verify-user');
    },
    gotoLogin() {
      this.$router.push('/_exterior/login');
    },
    doRegister() {
      if (!this.user.validate()) {
        return;
      }
      // Make sure user is logged off - otherwise the server connects a wrong profile
      this.$auth.logout();
      this.isLoading = true;
      this.$service.api.users.register(this.user)
        .then(() => {
          this.isLoading = false;
          this.$auth.login({ username: this.user.userName, password: this.user.password })
            .then(() => {
              this.gotoVerifyUser();
            });
        })
        .catch((error: any) => {
          this.isLoading = false;
          this.requestError = error;
        });
    },
  },
});

